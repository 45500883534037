<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>支付设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="150px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="微信支付" name="wxpay">
            <el-form-item label="微信公众号APPid">
              <el-input v-model="form.appid"></el-input>
            </el-form-item>
            <el-form-item label="支付商户号(Mch_Id)">
              <el-input v-model="form.mchid"></el-input>
            </el-form-item>
            <el-form-item label="支付秘钥(APIKEY)">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="CERT证书文件">
              <upload :url="form.cert" :filed="'cert'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
            <el-form-item label="KEY密钥文件">
              <upload :url="form.key" :filed="'key'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
            <el-form-item label="ROOT文件">
              <upload :url="form.root" :filed="'root'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="支付宝支付" name="second">
            <el-form-item label="支付宝APPid">
              <el-input v-model="form.alipayappid"></el-input>
            </el-form-item>
            <el-form-item label="验签方式 (SIGN_TYPE)">
              <el-radio-group v-model="form.signtype">
                <el-radio :label="1">RSA</el-radio>
                <el-radio :label="2">RSA2</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支付宝公钥">
              <el-input v-model="form.publickey"></el-input>
            </el-form-item>
            <el-form-item label="应用私钥">
              <el-input v-model="form.privatekey"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'Pay',
  components: {
    Upload
  },
  data () {
    return {
      activeName: 'wxpay',
      form: {
        appid: '',
        mchid: '',
        name: '',
        cert: '',
        key: '',
        root: '',
        alipayappid: '',
        signtype: 2,
        publickey: '',
        privatekey: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('pay')
      if (res.status === 200) {
        this.form.appid = res.data.appid
        this.form.mchid = res.data.mchid
        this.form.name = res.data.name
        this.form.cert = res.data.cert
        this.form.key = res.data.key
        this.form.root = res.data.root
        this.form.alipayappid = res.data.alipayappid
        this.form.signtype = res.data.signtype
        this.form.publickey = res.data.publickey
        this.form.privatekey = res.data.privatekey
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('pay', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.form[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.form[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.form[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.form[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
